<template>
  <div class="flex flex-col items-start">
    <span
      v-if="discount"
      class="z-10 bg-coloration-primary rounded-xl lg:text-sm text-white py-1 px-3 mb-2 whitespace-nowrap"
    >
      {{ $n(discount, 'percent') }} {{ $t('off') }}
    </span>

    <span
      v-if="isNew"
      class="flex z-10 bg-coloration-success rounded-xl lg:text-sm text-white py-1 px-3 whitespace-nowrap"
    >
      {{ $t('isNew') }}
    </span>
  </div>
</template>

<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});
const { price, priceBefore } = defineProps({
  priceBefore: {
    type: Number,
    default: undefined,
  },
  price: {
    type: Number,
    required: true,
  },
  isNew: {
    type: Boolean,
    required: true,
  },
});

const discount = computed(() => {
  if (!priceBefore) {
    return 0;
  }

  return (priceBefore - price) / priceBefore;
});
</script>
<i18n>
{
  "en": {
    "off": "off",
    "isNew": "New",
  },
  "ar": {
    "off": "خصم",
    "isNew": "جديد",
  }
}
</i18n>
